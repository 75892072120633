import debounce from "debounce";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AppContextStore } from "$stores/app-context";
import _ from "lodash";

const debouncedFetch = debounce((fetchList, globalFilters, storeState) => {
    fetchList(globalFilters, storeState);
}, 600);

export function usePaginatedStoreWrapper(store, options) {
    const { fetchList, reset, setPagingInfo, setPageIndex, setPerPage, setSelectedData, setHiddenColumns } = options;
    const overrideGlobalFilters = options.overrideGlobalFilters || {};
    const { hasLoadedFilters, globalFilters } = AppContextStore.useState((f) => {
        return { hasLoadedFilters: f.hasLoadedFilters, globalFilters: f.currentFilters ? { ...f.currentFilters, ...overrideGlobalFilters } : { ...overrideGlobalFilters }, overviewLoading: f.overviewLoading };
    });

    const [searchParams] = useSearchParams();

    const storeState = store.useState();

    useEffect(() => {
        return () => {
            if (reset) reset();
        };
    }, [reset]);

    useEffect(() => {
        if (hasLoadedFilters) {
            const updatables = {};
            const sort = JSON.parse(searchParams.get("sort"));
            const filter = JSON.parse(searchParams.get("filter"));
            const pageIndex = searchParams.get("pageIndex");
            const pageSize = searchParams.get("pageSize");

            if (sort) updatables.sort = sort;
            if (filter) updatables.filter = filter;
            if (pageIndex) updatables.pageIndex = parseInt(pageIndex, 10);
            if (pageSize) updatables.pageSize = parseInt(pageSize, 10);

            if (!_.isEmpty(updatables)) {
                setPagingInfo(updatables);
            }
        }
    }, [hasLoadedFilters, searchParams, setPagingInfo]);

    useEffect(() => {
        if (hasLoadedFilters) {
            debouncedFetch(fetchList, globalFilters, storeState);
        }
    }, [hasLoadedFilters, globalFilters, fetchList, storeState.filters, storeState.pageIndex, storeState.pageSize, storeState.sort]);

    return {
        ...storeState,
        fetchList,
        reset,
        setPagingInfo,
        setPageIndex,
        setPerPage,
        setSelectedData,
        setHiddenColumns,
    };
}
