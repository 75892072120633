import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag, faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";

import CollapsingPills from "$components/table/cells/collapsing-pills";
import LargeNumberRange from "$components/table/cells/large-number-range";
import LogoCell from "$components/table/cells/logo";
import PredefinedNumberRange from "$components/table/cells/predefined-number-range";
import ProbabilityBubble from "$components/probability";

import { MultiRangeFilter, ProbabilityRange, SelectColumnFilter } from "$components/table/components/filters/filter";
import { getTerm } from "$stores/dictionary";
import { Checkbox } from "$components/form-inputs/plain-inputs";

export const buildDefaultColumns = ({ settings = {}, updateSelectedList, filterOptions, globalFilterRanges, sendFlags }) => {
    var columns = [
        {
            Header: (props) => {
                if (!props) {
                    return "Feedback";
                }
                const { data, selectedData } = props;
                const allDataSelected = data.every((d) => selectedData.some((p) => p.key === d.key));
                return (
                    <div>
                        {selectedData.length > 0 ? (
                            <div>
                                <button className={classNames("button -text", { "-disabled": !selectedData.length })} title="Flag Selected" onClick={sendFlags}>
                                    Flag {!!selectedData.length && selectedData.length}
                                </button>
                            </div>
                        ) : (
                            <>&nbsp;</>
                        )}
                        <div className="controls">
                            <div className="button-group -center">
                                <Checkbox
                                    name="selected-state"
                                    value={allDataSelected}
                                    onChange={(e) => {
                                        updateSelectedList("bulk", data);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                );
            },
            accessorKey: "key", // accessor is the "key" in the data
            id: "key", // id is used as a sort key (i.e., actual api name)
            enableSorting: false,
            enableColumnFilter: false,
            Cell: ({ data, value, rowIndex, isSelected }) => {
                if (data.userFlagged) {
                    return <FontAwesomeIcon icon={faFlag} />;
                }
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            name={`select_${data.key}_${rowIndex}`}
                            value={isSelected}
                            onChange={(e) => {
                                updateSelectedList(isSelected ? "remove" : "add", [data]);
                            }}
                        />
                    </div>
                );
            },
            size: 50,
            className: "-center",
        },
        {
            Header: () => getTerm("projectType"),
            accessorKey: "name", // accessor is the "key" in the data
            id: "name", // id is used as a sort key (i.e., actual api name)
            size: 120,
            Cell: ({ data, value }) => {
                return <div>{value}</div>;
            },
        },
        {
            Header: () => getTerm("projectTypeGroup"),
            accessorKey: "projectTypeGroup", // accessor is the "key" in the data
            id: "projectTypeGroup", // id is used as a sort key (i.e., actual api name)
            size: 120,
        },
        {
            Header: () => getTerm("parentClient"),
            accessorKey: "clientGroupName",
            id: "clientGroupName",
            Cell: ({ data, value }) => <LogoCell logoUrl={data?.clientLogoUrl} value={value} />,
        },
        {
            Header: () => getTerm("subClient"),
            accessorKey: "clientName",
            id: "clientName",
            className: "",
        },
        {
            Header: () => getTerm("serviceLine"),
            accessorKey: "serviceLine",
            size: 120,
            Filter: SelectColumnFilter,
            filterOptions: { options: filterOptions?.practice?.values || [] },
            enableSorting: false,
            Cell: ({ data, value }) => {
                const newData = value ? [{ id: value, text: value }] : [];
                return <CollapsingPills data={newData} maxVisible={2} keyPrefix="serviceLine" />;
            },
        },
        {
            Header: () => "Flags",
            accessorKey: "flags",
            size: 120,
            Filter: SelectColumnFilter,
            filterOptions: {
                options: [
                    { value: "requiresIndependence", text: "Check Independence" },
                    { value: "isCrossSell", text: "Cross-Sell" },
                ],
            },
            enableSorting: false,
            Cell: ({ data, value }) => {
                const flags = [];
                if (data.requiresIndependence) {
                    flags.push({ id: "independence", text: "Check Independence", modifierClassNames: ["-independence"] });
                }
                if (data.isCrossSell) {
                    flags.push({ id: "cross-sell", text: "Cross-Sell", icon: faHandHoldingDollar, modifierClassNames: ["-importance"] });
                }

                return <CollapsingPills data={flags} maxVisible={2} keyPrefix="serviceLine" />;
            },
        },
        {
            Header: () => `${getTerm("parentClient")} Size`,
            accessorKey: "clientSize",
            id: "clientSizeSortOrder",
            sortDescFirst: true,
            Filter: SelectColumnFilter,
            filterOptions: { options: filterOptions?.clientSize?.values },
            Cell: ({ data, value }) => {
                return <PredefinedNumberRange value={value} isHealthy={true} />;
            },
        },
        {
            Header: () => getTerm("averageRevenue"),
            accessorKey: "averageRevenue",
            id: "averageRevenue",
            sortDescFirst: true,
            Filter: MultiRangeFilter,
            filters: {
                min: globalFilterRanges?.averageRevenueMin ? Math.floor(globalFilterRanges?.averageRevenueMin) : "",
                max: globalFilterRanges?.averageRevenueMax ? Math.ceil(globalFilterRanges?.averageRevenueMax) : "",
                format: { isCurrency: true },
            },
            Cell: ({ data, value }) => {
                return <LargeNumberRange value={value} decimalPrecision={0} />;
            },
        },
        {
            Header: () => "Probability",
            accessorKey: "probability",
            id: "probability",
            sortDescFirst: true,
            size: 100,
            Filter: ProbabilityRange,
            filters: {
                min: 1,
                max: 3,
                format: { isCurrency: false },
            },
            Cell: ({ data, value }) => <ProbabilityBubble value={value} />,
        },
    ];

    if (settings.visibleColumns && settings.visibleColumns.length > 0) {
        columns = columns.filter((c) => {
            return settings.visibleColumns.includes(c.accessorKey);
        });
    }

    if (Array.isArray(settings?.additionalColumns)) {
        settings.additionalColumns.forEach((c) => {
            const pos = c.position || columns.length;
            columns.splice(pos, 0, c);
        });
    }

    return columns;
};

export const experienceColumn = {
    Header: () => "Experience",
    accessorKey: "team",
    enableColumnFilter: false,
    enableSorting: false,
    id: "team",
    sortDescFirst: true,
    size: 100,
    position: 4,
    Cell: (data) => {
        const team = data.value || [];
        const value = team.reduce((exp, t) => {
            exp.push(...t.expertType);
            return exp;
        }, []);
        return (
            <div className="expert-in">
                {expertTypes.map((t) => {
                    const isExpert = value.some((v) => v.id.toString() === t.id.toString());
                    return (
                        <div key={`expertIn${t.id}`} className={classNames({ "-active": isExpert })} data-tooltip={t.text}>
                            <span>
                                <FontAwesomeIcon icon={getExpertInIcon(t.id).icon} />
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    },
};
