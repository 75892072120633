import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import TableLoader from "$components/table/table-loader.js";

import { buildProjectsManagedColumns } from "./lib/columns.js";
import { mapToTableData } from "src/pages/projects/existing/list.js";
import { ProjectsManagedStore, fetchProjectsManaged, setFilters, setHiddenColumns, setPagingInfo, setPageIndex, setPerPage, setSort } from "$stores/managed-projects-list.js";
import { getTerm } from "$stores/dictionary.js";
import { AppContextStore } from "$stores/app-context.js";
import { mapByKey } from "$utils/mapping.js";
import { usePaginatedStoreWrapper } from "$utils/hooks/usePaginatedStore.js";

const ManagedProjects = () => {
    const { employeeId } = useParams();
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const filterMenu = AppContextStore.useState((f) => mapByKey(f.filterMenu, "apiKey") || {});

    const { projectsManaged, filters, globalFilterRanges, hiddenColumns, isLoading, isSortAsc, pageCount, pageIndex, pageSize, sort } = usePaginatedStoreWrapper(ProjectsManagedStore, {
        fetchList: fetchProjectsManaged,
        setPagingInfo: setPagingInfo,
        overrideFilters: { crm: employeeId },
    });

    const [projectsManagedColumns, setProjectsManagedColumns] = useState([]);

    useEffect(() => {
        setProjectsManagedColumns(buildProjectsManagedColumns({ globalFilterRanges, filterOptions: filterMenu }));
    }, [filterMenu, globalFilterRanges]);

    const rowClick = (row) => {
        navigate(`/project/${row.id}/existing`);
        appInsights.trackEvent(
            { name: "Navigation" },
            {
                label: "Projects Managed",
                section: "Projects Managing",
                projectId: row.id,
            }
        );
    };

    const data = projectsManaged.map(mapToTableData);

    return (
        <div>
            <h1>
                <span data-tooltip={`${getTerm("existingProjects")} where the ${getTerm("teamMember").toLowerCase()} is the ${getTerm("projectPIC")}`}>
                    <em>{getTerm("existingProjects")}</em> as {getTerm("projectPIC")}
                </span>
            </h1>
            <TableLoader
                allowFilter={true}
                columns={projectsManagedColumns}
                containerClass="table-projects-managed"
                data={data}
                filters={filters}
                hiddenColumns={hiddenColumns}
                isLoading={isLoading}
                isSortAsc={isSortAsc}
                pageIndex={pageIndex}
                pageSize={pageSize}
                pageCount={pageCount}
                setFilters={setFilters}
                setHiddenColumns={setHiddenColumns}
                setPageIndex={setPageIndex}
                setPerPage={setPerPage}
                setSort={setSort}
                sort={sort}
                onRowClick={rowClick}
            />
            <br />
        </div>
    );
};

export default ManagedProjects;
