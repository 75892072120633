import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import TableLoader from "$components/table/table-loader.js";

import { buildClientsManagedColumns } from "./lib/columns.js";
import { mapToTableData } from "src/pages/projects/existing/list.js";
import { ClientsManagedStore, fetchClientsManaged, reset, setFilters, setHiddenColumns, setPageIndex, setPagingInfo, setPerPage, setSort } from "$stores/managed-clients-list.js";
import { getTerm } from "$stores/dictionary.js";
import { setSelectedSubclient } from "$stores/subclients";
import { AppContextStore } from "$stores/app-context.js";
import { mapByKey } from "$utils/mapping.js";
import { usePaginatedStoreWrapper } from "$utils/hooks/usePaginatedStore.js";

const ManagedClients = () => {
    const { employeeId } = useParams();
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const filterMenu = AppContextStore.useState((f) => mapByKey(f.filterMenu, "apiKey") || {});
    const [clientsManagedColumns, setClientsManagedColumns] = useState([]);

    const { clientsManaged, filters, globalFilterRanges, hiddenColumns, isLoading, isSortAsc, pageCount, pageIndex, pageSize, sort } = usePaginatedStoreWrapper(ClientsManagedStore, {
        fetchList: fetchClientsManaged,
        reset: reset,
        setPagingInfo: setPagingInfo,
        overrideFilters: { crm: employeeId },
    });

    useEffect(() => {
        setClientsManagedColumns(buildClientsManagedColumns({ filterOptions: filterMenu, globalFilterRanges }));
    }, [filterMenu, globalFilterRanges]);

    const rowClick = (row) => {
        navigate(`/client/${row.clientGroupid}/sub-clients#client_${row.id}`);
        setSelectedSubclient(row.id, row.name);
        appInsights.trackEvent(
            { name: "Navigation" },
            {
                label: "Clients Managed",
                section: "Clients Managing",
                projectId: row.id,
            }
        );
    };

    const data = clientsManaged.map(mapToTableData);

    return (
        <div>
            <h1>
                <span data-tooltip={`${getTerm("subClients")} where the ${getTerm("teamMember").toLowerCase()} is the ${getTerm("subCrm")}`}>
                    <em>{getTerm("subClients")}</em> as {getTerm("subCrm")}
                </span>
            </h1>
            <TableLoader
                allowFilter={true}
                columns={clientsManagedColumns}
                containerClass="table-clients-managed"
                data={data}
                filters={filters}
                hiddenColumns={hiddenColumns}
                isLoading={isLoading}
                isSortAsc={isSortAsc}
                pageIndex={pageIndex}
                pageSize={pageSize}
                pageCount={pageCount}
                setFilters={setFilters}
                setHiddenColumns={setHiddenColumns}
                setPageIndex={setPageIndex}
                setPerPage={setPerPage}
                setSort={setSort}
                sort={sort}
                onRowClick={rowClick}
            />
            <br />
        </div>
    );
};

export default ManagedClients;
